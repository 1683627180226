<template>
    <div class="trip-request">
        <template v-if="loaded">
            <div v-if="container.main" class="trip-request__main-container">
                <div class="trip-request__logo" v-if="isDesktop"></div>

                <template v-if="filteredTrips.length">
                    <ValidationObserver ref="validator" slim>
                        <h1 class="trip-request__page-name">Выберите тур</h1>
                        <ValidationProvider rules="required">
                            <BaseSelect
                                :value="bloggerTrip.trip"
                                :items="filteredTrips"
                                item-text="name"
                                item-value="_id"
                                :error-messages="errors[0]"
                                :readonly="tripRequest.trip"
                                @input="setTrip" />
                        </ValidationProvider>

                        <h1 class="trip-request__page-name">Укажите стоимость ({{currency}})</h1>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="bloggerTrip.price"
                                :placeholder="currency"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>

                        <h1 class="trip-request__page-name">Выберите дату начала заезда тура</h1>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseDatePicker
                                :value="bloggerTrip.start"
                                @change="setStart"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>

                        <h1 class="trip-request__page-name">Выберите конечную дату заезда тура</h1>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseDatePicker
                                :value="bloggerTrip.finish"
                                @change="setFinish"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton
                        class="trip-request__step-buttons__button button-fill"
                        style="width: 100%"
                        @click="save"
                    >Отправить предложение</BaseButton>
                </template>
                <template v-else>
                    <div class="trip-request__text">
                        Вы можете откликаться на запросы только турами, которые одобрены нашими администраторами.<br />
                        Если у вас есть вопросы, вы можете написать нам в <a href="supportLink" target="_blank">чат поддержки</a>.
                    </div>
                </template>
            </div>
            <div v-if="container.complete" class="trip-request__complete">
                <div class="trip-request__complete__container">
                    <div class="trip-request__complete__check"></div>
                    <div class="trip-request__complete__text">Спасибо!</div>
                    <BaseButton
                        class="button-fill"
                        @click="goTo('operator-trip-requests')"
                    >Вернуться к списку запросов</BaseButton>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { TRIP_STATUS, BLOGGER_TRIP_STATUS } from '@/vars';

    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseSelect from '../../components/common/BaseSelect.vue';
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue';

    export default {
        name: 'TourRequest',
        metaInfo: {
            title: 'Запрос на тур'
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseTextField,
            BaseButton,
            BaseSelect,
            BaseDatePicker,
        },
        data: () => ({
            TRIP_STATUS,
            BLOGGER_TRIP_STATUS,
            container: {
                main: true,
                complete: false,
            },
            step: 1,
            errors: {
                pictures: ''
            },
            isDesktop: false,
            progress: 14,
            russia: false,
            bloggerTrip: {
                trip: null,
                blogger: null,
                organizer: null,
                tripRequest: null,
                price: null,
                start: '',
                finish: '',
            },
            loaded: false,
            durationIndex: null,
        }),
        computed: {
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('tripRequests', {
                tripRequest: state => state.entity
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            filteredTrips() {
                return this.trips.filter(item => item.status === TRIP_STATUS.ACTIVE);
            },
            supportLink() {
                return `https://t.me/${process.env.VUE_APP_TELEGRAM_SUPPORT_BOT}`;
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY;
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            store.commit('tripRequests/CLEAR_ENTITY');
            await store.dispatch('tripRequests/get', { id: this.$route.params.tripRequestId });
            this.bloggerTrip = {
                ...this.bloggerTrip,
                status: BLOGGER_TRIP_STATUS.BLOGGER_MODERATED,
                blogger: this.tripRequest.blogger._id,
                organizer: this.user._id,
                tripRequest: this.tripRequest._id,
            }
            store.commit('trips/CLEAR_ENTITIES');
            await this.getTrips();
            if(this.tripRequest.trip) {
                this.bloggerTrip.trip = this.tripRequest.trip._id;
            } else {
                this.bloggerTrip.trip = this.filteredTrips.length ? this.filteredTrips[0]._id : null;
            }
            this.loaded = true;
        },
        methods: {
            async getTrips() {
                await store.dispatch('trips/fetch', { 
                    filter: {
                        ...{ user: this.user._id } 
                    }, 
                    page: this.page 
                });
            },
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async save() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    store.commit('bloggerTrips/SET_ENTITY', {
                        ...this.bloggerTrip
                    });
                    await store.dispatch('bloggerTrips/save');
                    this.complete();
                }
            },
            complete() {
                this.container.main = false;
                this.container.complete = true;
            },
            setTrip(value) {
                this.bloggerTrip.trip = value;
            },
            setStart(value) {
                this.bloggerTrip.start = value;
            },
            setFinish(value) {
                this.bloggerTrip.finish = value;
            },
        },
    };
</script>

<style lang="scss">
.trip-request {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    @media all and (max-width: 768px) {
        width: 100%;
    }
    &__logo {
        height: 30px;
        width: 260px;
        background: url(../../assets/Main-logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 60px;
        @media all and (max-width: 768px) {
            margin-bottom: 32px;
        }
    }
    &__tabs-outline {
        border-bottom: 1px solid #E5E6E9;
        margin: 32px 0 16px;
    }
    &__page-name {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0 12px;
        @media all and (max-width: 768px) {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
        }
    }
    
    &__main-container {
        display: flex;
        flex-direction: column;
        // margin-bottom: 40px;
        width: 650px;
        height: 100%;
	    padding-top: 80px;
        padding-bottom: 160px;
        margin: 0 auto;
        @media all and (max-width: 768px) {
            width: 100%;
            padding: 20px 20px 160px;
        }
        &__items {
            width: 650px;
            height: 100%;
            // padding: 20px;
            // background: #FFFFFF;
            // box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            // border-radius: 12px;
            margin: 0 auto;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__text {
        padding: 20px 0;
        font-size: 16px;
        text-align: center;
    }

    &__complete {
        height: 100vh;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            max-width: 445px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (max-width:1024px) {
                padding:  0 40px;
            }
        }
        &__check {
            height: 100px;
            width: 100px;
            background: url(../../assets/quiz-check.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 32px;
        }
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            text-align: center;
            color: #273155;
            margin-bottom: 12px;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #A1A4B1;
            @media all and (max-width:768px) {
                font-size: 18px;
                line-height: 140%;
            }
        }
    }
    
    .buttons {
        display: flex;
        flex-direction: column;
    }
}
</style>