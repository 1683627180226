<template>
    <v-text-field
        :value="value"
        class="base-text-field"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-for="(scope, slot) in $slots" :slot="slot">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </v-text-field>
</template>

<script>
    export default {
        name: 'BaseTextField',
        inheritAttrs: false,
        props: {
            value: {
                type: [String, Number],
                default: ''
            }
        }
    }
</script>

<style lang="scss">
    .base-text-field,
    .v-text-field--outlined.base-text-field {
        margin-bottom: 16px !important;
        border-radius: 10px;

        .v-text-field__slot {
            padding: 0;
            color: #273155;
            min-height: 56px;
        }
        .v-input__slot {
            border: 1px solid #EBEBEB;
            border-radius: 10px;
            box-shadow: rgba(30, 28, 46, 0.03);
            background: #fff !important;
            margin-bottom: 0;
        }
        &.form {
            .v-input__slot {
                border: 1px solid #A9AEB2;
            }
            input {
                bottom: 17px;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                @media all and (min-width: 768px) {
                
                }
            }
        }
        .v-counter {
            position: absolute;
            top: 22px;
            right: 20px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 140%;
            letter-spacing: -0.02em;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        }
        // &.v-text-field--outlined.v-input--is-focused fieldset,
        // &.v-text-field--outlined.v-input--has-state fieldset {
        //     border: 1px solid #000;
        // }
		&.v-text-field--outlined.error--text.v-input--is-focused fieldset,
        &.v-text-field--outlined.error--text.v-input--has-state fieldset {
            border: 1px solid #ff5252 ;
        }
        &.v-text-field--outlined.v-input--is-focused .v-counter,
        &.v-text-field--outlined.v-input--is-dirty .v-counter {
            top: 12px;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            @media all and (min-width: 768px) {
                display: none;
            }
        }
        input {
            padding: 0;
            position: absolute;
            bottom: 10px;
            left: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #273155 !important;
            width: calc(100% - 12px);
            @media all and (min-width: 768px) {
                bottom: 18px;
                font-size: 16px;
            }
        }
        &.v-text-field--outlined fieldset {
            border: none;
        }
        .v-text-field__details {
            min-height: 0;
            padding: 0 !important;
        }
        &.v-text-field.v-text-field--enclosed .v-text-field__details {
            margin-bottom: 0;
        }
        .v-messages.theme--light {
            min-height: 0;
        }
        &.v-text-field--outlined .v-label {
            margin-left: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            @media all and (min-width: 768px) {
                display: none
            }
        }
        &.v-text-field--outlined .v-label--active {
            top: 35px;
        }

        .v-messages.theme--light.error--text {
            margin-top: 4px;
        }
        .v-text-field--outlined {
            border: none;
        }
        fieldset {
            display:none
        }
    }
// .label-on {
//         .base-text-field,
//         .v-text-field--outlined.base-text-field
//         .v-text-field--outlined .v-label {
//             @media all and (min-width: 768px) {
//                 display: flex;
//             }
//         }
//         .base-text-field.v-text-field--outlined .v-label, 
//         .v-text-field--outlined.base-text-field.v-text-field--outlined .v-label {
//             @media all and (min-width: 768px) {
//                 display: visible;
//             }
//         }
//     } 
</style>
